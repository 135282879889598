<template>
  <div class="customer-booking">
    <Details :data="detailsData" v-if="details" />
    <v-card tile flat v-else-if="!data.result.length" class="d-flex justify-center align-center" :height="`62vh`">
      <div>
        <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
        <div class="text-center text--text mt-4">Sorry, but nothing found</div>
      </div>
    </v-card>
    <div v-else>
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="headers"
        :items="data.result"
        item-key="id"
        :items-per-page="-1"
        class="d-sm-block d-none"
        :height="'70vh'"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id" class="link" @click="openBoocking(item)">
              <td>{{ item.number }}</td>
              <td>{{ item.trip.title_en }}</td>
              <td class="text-capitalize">{{ item.plan_type }}</td>
              <td>
                {{ new Date(item.starts_at).toLocaleString('en-GB', { day: 'numeric', month: 'short' }) }} -
                {{ new Date(item.ends_at).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) }}
              </td>
              <td>{{ (item.total / 100).toFixed(2) }}</td>
              <td class="text-capitalize">{{ item.status }}</td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer>
          <v-divider></v-divider>
          <div class="mt-2 pb-2 px-3 d-flex align-center">
            <v-spacer></v-spacer>
            <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
            <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <div class="d-block d-sm-none">
        <div v-for="item in data.result" :key="item.id" class="px-4 pt-2" @click="openBoocking(item)">
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Booking ID</div>
            <div class="font-weight-medium f18">{{ item.number }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Trip</div>
            <div class="font-weight-medium f18 text-truncate">{{ item.trip.title_en }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Plan type</div>
            <div class="font-weight-medium f18 text-capitalize">{{ item.plan_type }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Date</div>
            <div class="font-weight-medium f18 text-capitalize">
              {{ new Date(item.starts_at).toLocaleString('en-GB', { day: 'numeric', month: 'short' }) }} -
              {{ new Date(item.ends_at).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) }}
            </div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Total Price</div>
            <div class="font-weight-medium f18">{{ (item.total / 100).toFixed(2) }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Status</div>
            <div class="font-weight-medium f18 text-capitalize">{{ item.status }}</div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="mt-2 pb-2 px-3 d-flex align-center">
          <v-spacer></v-spacer>
          <div class="ms-2">
            <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
            <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Details from '@/components/customer/BookingDetail';

export default {
  components: {
    Details,
  },
  data() {
    return {
      details: false,
      detailsData: {},
      page: 1,
      headers: [
        { text: 'Booking ID', value: 'number', sortable: false },
        { text: 'Trip', value: 'trip', sortable: false },
        { text: 'Plan type', value: 'plan', sortable: false },
        { text: 'Date', value: 'starts_at', sortable: false },
        { text: 'Total Price', value: 'total', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    openBoocking(i) {
      this.detailsData = i;
      this.details = true;
      this.$store.dispatch('setLink', i.number);
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store.dispatch('getCustomerItemBookings', this.page);
    },
  },
  watch: {
    link(val) {
      val ? '' : (this.details = false);
    },
  },
  computed: {
    data() {
      return this.$store.getters.customerBookings;
    },
    link() {
      return this.$store.getters.link;
    },
  },
};
</script>
