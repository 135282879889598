<template>
  <div>
    <v-card tile flat v-if="!data.result.length" class="d-flex justify-center align-center" :height="`62vh`">
      <div>
        <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
        <div class="text-center text--text mt-4">Sorry, but nothing found</div>
      </div>
    </v-card>
    <div v-else>
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="headers"
        :items="data.result"
        item-key="id"
        class="d-sm-block d-none"
        :items-per-page="-1"
        height="70vh"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.booking_number }}</td>
              <td>{{ item.phone_number ? item.phone_number : item.identifier }}</td>
              <td>{{ (item.amount / 100).toFixed(2) }}</td>
              <td class="text-capitalize">{{ item.status }}</td>
              <td>
                {{
                  new Date(item.created_at).toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                }}
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer>
          <v-divider></v-divider>
          <div class="mt-2 pb-2 px-3 d-flex align-center">
            <v-spacer></v-spacer>
            <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
            <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <div class="d-block d-sm-none">
        <div v-for="item in data.result" :key="item.id" class="px-4 pt-2">
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Booking ID</div>
            <div class="font-weight-medium f18">{{ item.booking_number }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Phone number/CIF</div>
            <div class="font-weight-medium f18 text-truncate">{{ item.phone_number ? item.phone_number : item.identifier }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Amount</div>
            <div class="font-weight-medium f18 text-capitalize">{{ (item.amount / 100).toFixed(2) }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Status</div>
            <div class="font-weight-medium f18 text-capitalize">{{ item.status }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Date</div>
            <div class="font-weight-medium f18">
              {{
                new Date(item.created_at).toLocaleString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="mt-2 pb-2 px-3 d-flex align-center">
          <v-spacer></v-spacer>
          <div class="ms-2">
            <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
            <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      headers: [
        { text: 'Booking ID', value: 'booking_number', sortable: false },
        { text: 'Phone number/CIF', value: 'phone_number', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Date', value: 'created_at', sortable: false },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store.dispatch('getCustomerItemAnb', this.page);
    },
  },
  computed: {
    data() {
      return this.$store.getters.customerAnb;
    },
  },
};
</script>
