<template>
  <div>
    <v-card tile flat v-if="!data.length" class="d-flex justify-center align-center" :height="`62vh`">
      <div>
        <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
        <div class="text-center text--text mt-4">Sorry, but nothing found</div>
      </div>
    </v-card>
    <div v-else>
      <v-data-table
        :items-per-page="-1"
        hide-default-footer
        fixed-header
        :headers="headers"
        :items="data"
        item-key="id"
        class="d-md-block d-none"
        height="70vh"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td style="max-width: 200px" class="text-truncate">{{ item.device_id }}</td>
              <td style="max-width: 90px">{{ item.platform }}</td>
              <td style="max-width: 200px" class="text-truncate">{{ item.fcm_token }}</td>
              <td style="max-width: 90px">{{ item.lang }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div class="d-block d-md-none">
        <div v-for="item in data" :key="item.id" class="px-4 pt-2">
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Device ID</div>
            <div class="font-weight-medium f18 text-truncate data-table">{{ item.device_id }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Platform</div>
            <div class="font-weight-medium f18">{{ item.platform }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">FMC token</div>
            <div class="font-weight-medium f18 text-truncate data-table">{{ item.fcm_token }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Languale</div>
            <div class="font-weight-medium f18">{{ item.lang }}</div>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: 'Device ID', value: 'device_id', sortable: false },
        { text: 'Platform', value: 'platform', sortable: false },
        { text: 'FMC token', value: 'fcm_token', cellClass: 'text-truncate', sortable: false },
        { text: 'Languale', value: 'lang', sortable: false },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getCustomerItemDevices');
  },
  computed: {
    data() {
      return this.$store.getters.customerDevices;
    },
  },
};
</script>
