<template>
  <div class="customer-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/customers">Customers</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span @click="number ? backDetail() : ''" :class="number ? 'disabled--text link' : 'black--text'">
        {{ data.first_name }} {{ data.last_name }}
      </span>
      <span v-if="number">
        <v-icon color="disabled">mdi-chevron-right</v-icon><span @click="backBooking" class="disabled--text link">Bookings</span>
        <v-icon color="disabled">mdi-chevron-right</v-icon><span>{{ number }}</span>
      </span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="(item, i) in menuItems"
            :key="item"
            :class="link == i + 1 ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="(link = i + 1), backBooking()"
            class="item-menu_item link"
          >
            <div>{{ item }}</div>
          </div>
        </v-card>
        <v-divider style="z-index: 9" vertical></v-divider>
        <div class="width100">
          <div v-if="link == 1">
            <Detail />
          </div>
          <div v-if="link == 2">
            <Devices />
          </div>
          <div v-if="link == 3">
            <Bookings />
          </div>
          <div v-if="link == 4">
            <Payments />
          </div>
          <div v-if="link == 5">
            <Mokafa />
          </div>
          <div v-if="link == 6">
            <Anb />
          </div>
          <div v-if="link == 7">
            <Digital />
          </div>
          <div v-if="link == 8">
            <Rewievs />
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Detail from '@/components/customer/Detail';
import Devices from '@/components/customer/Devices';
import Bookings from '@/components/customer/Bookings';
import Payments from '@/components/customer/Payments';
import Mokafa from '@/components/customer/Mokafa';
import Anb from '@/components/customer/Anb';
import Digital from '@/components/customer/Digital';
import Rewievs from '@/components/customer/Rewievs';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Detail,
    Devices,
    Bookings,
    Payments,
    Mokafa,
    Anb,
    Digital,
    Rewievs,
  },
  data() {
    return {
      link: 1,
      menuItems: [
        'General details',
        'Devices',
        'Bookings',
        'Payments',
        'Mokafaa transactions',
        'ANB transactions',
        'Digital wallet transactions',
        'Reviews',
      ],
    };
  },
  methods: {
    backDetail() {
      this.link = 1;
      this.backBooking();
    },
    backBooking() {
      this.$store.dispatch('setLink', '');
    },
  },
  mounted() {
    this.$store.dispatch('getCustomerItem');
  },
  computed: {
    data() {
      return this.$store.getters.customer;
    },
    number() {
      return this.$store.getters.link;
    },
  },
  destroyed() {
    this.$store.dispatch('setCustomerItem');
  },
};
</script>

<style lang="scss">
.customer-item {
  table thead tr th {
    background: white !important;
  }
}
</style>
