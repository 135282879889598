<template>
  <div>
    <div class="item-content pt-10 pr-10 pb-7 pl-7">
      <div class="d-flex align-start">
        <v-img height="162" max-width="140" contain :src="data.trip.picture"></v-img>
        <div class="ms-5">
          <div class="text-h5 font-weight-bold mb-1">
            {{ data.trip.title }}
            <v-btn :to="`/trips/${data.trip.id}`" target="_blank" height="28" min-width="28" class="px-0" outlined color="gray lighten-1">
              <v-icon class="text-h6" color="primary lighten-1">mdi-link mdi-rotate-135</v-icon>
            </v-btn>
          </div>
          <div class="mb-1">{{ data.number }}</div>
          <div class="mb-1 text--text">
            {{ new Date(data.starts_at).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) }} -
            {{ new Date(data.ends_at).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) }}
          </div>
          <div class="text--text">
            {{ data.trip.description }}
          </div>
        </div>
        <v-card flat :class="getColor(data.status)" class="font-weight-bold text-capitalize py-2 px-6 ms-auto">{{ data.status }}</v-card>
      </div>
    </div>
    <v-divider></v-divider>
    <div v-if="data.general_plan">
      <div class="item-content d-flex pl-7 pr-10 py-4">
        <div class="font-weight-bold">
          {{ data.general_plan.title_en }}<br /><span class="text-capitalize text--text text-body-2">{{ data.plan_type }}</span>
        </div>
        <v-spacer></v-spacer>
        <div class="text--text text-uppercase">{{ data.currency }} {{ (data.general_plan.price / 100).toFixed(2) }}</div>
      </div>
      <v-divider></v-divider>
      <div v-if="data.general_plan.accommodation">
        <div class="item-content d-flex pl-7 pr-10 py-4">
          <div>
            <div>Accomodation:</div>
            <div class="d-flex align-center">
              <div class="font-weight-bold me-2">{{ data.general_plan.accommodation.title_en }}</div>
              <v-rating
                class="pb-1"
                color="warning"
                readonly
                :length="data.general_plan.accommodation.stars"
                size="14"
                :value="data.general_plan.accommodation.stars"
              ></v-rating>
            </div>
            <div class="text--text">
              {{ data.general_plan.accommodation.plan.seats }} seats
              <span class="mx-2 gray--text text--lighten-1">•</span>
              <span class="text-capitalize">{{ data.general_plan.accommodation.plan.type }}</span>
              <span class="mx-2 gray--text text--lighten-1">•</span>
              {{ data.general_plan.nights }} night
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="text--text text-uppercase">
            {{ data.currency }} {{ (data.general_plan.accommodation.plan.price / 100).toFixed(2) }}
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
    <div v-if="data.personal_plan">
      <div class="item-content d-flex pl-7 pr-10 py-4">
        <div class="font-weight-bold">
          {{ data.personal_plan.title_en }}<br /><span class="text-capitalize text--text text-body-2">{{ data.plan_type }}</span>
        </div>
        <v-spacer></v-spacer>
        <div class="text--text text-uppercase">{{ data.currency }} {{ (data.personal_plan.price / 100).toFixed(2) }}</div>
      </div>
      <v-divider></v-divider>
      <div v-if="data.personal_plan.accommodation">
        <div class="item-content d-flex pl-7 pr-10 py-4">
          <div>
            <div>Accomodation:</div>
            <div class="d-flex align-center">
              <div class="font-weight-bold me-2">{{ data.personal_plan.accommodation.title_en }}</div>
              <v-rating
                class="pb-1"
                color="warning"
                readonly
                :length="data.personal_plan.accommodation.stars"
                size="14"
                :value="data.personal_plan.accommodation.stars"
              ></v-rating>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
    <div v-if="data.addons.length">
      <div class="item-content pl-7 pr-10 py-4">
        <div class="font-weight-bold">Addons</div>
        <div class="d-flex" v-for="item in data.addons" :key="item.id">
          <div>{{ item.title }}</div>
          <v-spacer></v-spacer>
          <div class="text-uppercase text--text">{{ item.currency }} {{ (item.price / 100).toFixed(2) }}</div>
        </div>
      </div>
      <v-divider></v-divider>
    </div>
    <div v-if="data.discount">
      <div class="item-content d-flex pl-7 pr-10 py-4">
        <div class="font-weight-bold">
          Discount code: <span class="secondary--text font-weight-regular">{{ data.discount.code }}</span>
        </div>
        <v-spacer></v-spacer>
        <div class="text--text text-uppercase">{{ data.currency }} {{ (data.discount_amount / 100).toFixed(2) }}</div>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="item-content d-flex pl-7 pr-10 py-4">
      <div class="font-weight-bold">Digital wallet</div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <div class="text--text">
          Charged: {{ (data.charged_reward_amount / 100).toFixed(2) }} <v-icon class="pb-1" color="error">mdi-arrow-down mdi-18px</v-icon>
        </div>
        <v-divider class="mx-4" vertical></v-divider>
        <div class="text--text">
          Enrolled: {{ (data.enrolled_reward_amount / 100).toFixed(2) }} <v-icon class="pb-1" color="success">mdi-arrow-up mdi-18px</v-icon>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div v-if="data.mokafaa">
      <div class="item-content d-flex pl-7 pr-10 py-4">
        <div class="font-weight-bold">Mokafaa</div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <div class="text--text">{{ data.mokafaa.phone_number }}</div>
          <v-divider class="mx-4" vertical></v-divider>
          <div class="text--text text-capitalize">{{ data.mokafaa.status }}</div>
        </div>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="item-content d-flex pl-7 pr-10 py-4">
      <div>{{ data.seats }} seats</div>
      <v-spacer></v-spacer>
      <div class="text-end">
        <div>
          <span class="text--text">VAT: </span><span class="text-uppercase"> {{ data.currency }} {{ (data.vat / 100).toFixed(2) }}</span>
        </div>
        <div>
          <span class="text--text">Service fee: </span>
          <span class="text-uppercase"> {{ data.currency }} {{ (data.service_fee / 100).toFixed(2) }}</span>
        </div>
        <div>
          <span class="text--text">Total: </span>
          <span class="text-uppercase font-weight-bold"> {{ data.currency }} {{ (data.total / 100).toFixed(2) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  methods: {
    getColor(c) {
      if (c == 'canceled') {
        return 'error lighten-5 error--text';
      } else if (c == 'canceled') {
        return 'success lighten-5 success--text';
      } else {
        return 'primary lighten-3 primary--text';
      }
    },
  },
};
</script>
