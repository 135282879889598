<template>
  <div>
    <v-card tile flat v-if="!data.pages" class="d-flex justify-center align-center" :height="`62vh`">
      <div>
        <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
        <div class="text-center text--text mt-4">Sorry, but nothing found</div>
      </div>
    </v-card>
    <div v-else>
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="headers"
        :items="data.result"
        item-key="id"
        class="d-sm-block d-none"
        :items-per-page="-1"
        height="70vh"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id" @click="openLink(item.trip.id)" class="link">
              <td>{{ item.trip.title }}<br /><span class="primary--text">Link</span></td>
              <td>{{ item.text }}</td>
              <td>
                <v-rating
                  empty-icon="mdi-star"
                  full-icon="mdi-star"
                  half-icon="mdi-star-half-full"
                  readonly
                  background-color="gray lighten-2"
                  color="warning"
                  length="5"
                  size="16"
                  :value="item.rating"
                ></v-rating>
              </td>
              <td>
                {{
                  new Date(item.submitted_at).toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                }}
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer>
          <v-divider></v-divider>
          <div class="mt-2 pb-2 px-3 d-flex align-center">
            <v-spacer></v-spacer>
            <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
            <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <div class="d-block d-sm-none">
        <div v-for="item in data.result" :key="item.id" class="px-4 pt-2">
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Trip</div>
            <div class="font-weight-medium f18">{{ item.trip.title }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Comment</div>
            <div class="font-weight-medium f18 text-truncate">{{ item.text }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Rating</div>
            <div class="font-weight-medium f18">
              <v-rating
                empty-icon="mdi-star"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                readonly
                color="warning"
                length="5"
                size="16"
                :value="item.rating"
              ></v-rating>
            </div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Date</div>
            <div class="font-weight-medium f18">
              {{
                new Date(item.submitted_at).toLocaleString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="mt-2 pb-2 px-3 d-flex align-center">
          <v-spacer></v-spacer>
          <div class="ms-2">
            <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
            <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      headers: [
        { text: 'Trip', value: 'trip', sortable: false },
        { text: 'Comment', value: 'text', sortable: false },
        { text: 'Rating', value: 'rating', sortable: false },
        { text: 'Date', value: 'submitted_at', sortable: false },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      const data = new Object();
      data.page = this.page;
      data.type = `?customer=${this.$route.params.customerId}&`;
      await this.$store.dispatch('getReviewList', data);
    },
    openLink(id) {
      const route = this.$router.resolve(`/trips/${id}/general`);
      window.open(route.href, '_blank');
    },
  },
  computed: {
    data() {
      return this.$store.getters.reviewList;
    },
  },
};
</script>
